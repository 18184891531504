import React, { useMemo } from 'react';
import styled from 'styled-components';
import PreviewPhoto from 'public/pool/default-header-mobile-bg-support.png';
import { Pool } from 'templates/pool/types';

import { media } from 'utils/media';
import { fontAdjusts } from 'utils/stringFormat';
import Image from 'next/image';

export interface PoolCardProps {
  data: Pool;
}

const PoolCard = ({ data }: PoolCardProps) => {
  const fontSize = useMemo(() => {
    const values = [
      fontAdjusts(`${data?.target?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}`, 18),
      fontAdjusts(`${data?.raised?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}`, 18),
      fontAdjusts(`${data?.supporters}}`, 18),
    ];
    return Math.min(...values);
  }, [data]);

  const fixedFee = useMemo(() => (Number(data.raised) * Number(data.percentageFee)) / 100, [data]);

  const convertValue = (value?: number) => {
    if (!value) return 0;
    else return value / 100;
  };

  const formatAmount = (value: string | number) => {
    return value?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
  };

  return (
    <Container>
      <div
        style={
          data.cover
            ? {
                backgroundImage: `url(${data.cover})`,
                backgroundPosition: 'top',
                backgroundSize: 'cover',
              }
            : {
                backgroundImage: `url(${PreviewPhoto.src})`,
                backgroundPosition: 'top',
                backgroundSize: '80% auto',
                backgroundRepeat: 'no-repeat',
              }
        }
      ></div>

      <div>
        <div>
          <h2>{data.title}</h2>
          <Description>{data.description}</Description>
        </div>
      </div>

      <div>
        <div>
          <span>Meta:</span>
          <strong
            style={{
              fontSize,
            }}
            title={formatAmount(convertValue(data?.target as number))}
          >
            {formatAmount(convertValue(data?.target as number))}
          </strong>
        </div>
        <div>
          <span>Total arrecadado:</span>
          <strong
            style={{
              fontSize,
            }}
            title={formatAmount(convertValue(data?.raised as number))}
          >
            {formatAmount(convertValue(data?.raised) - Number(convertValue(fixedFee)))}
          </strong>
        </div>
        <div>
          <span>Apoiadores:</span>
          <strong
            style={{
              fontSize,
            }}
            title={`${data.supporters}`}
          >
            {data.supporters}
          </strong>
        </div>
      </div>
    </Container>
  );
};

const Container = styled.div`
  background-color: #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: fit-content;
  width: 400px;
  height: 454px;
  padding: 11px;
  color: #000;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  text-overflow: ellipsis !important;

  & > div {
    &:first-child {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #f5f5f5;
      min-height: 200px;
      border-radius: 8px;
      margin-bottom: 1rem;
    }
    &:nth-child(2) {
      flex: 1;
      display: flex;
      flex-direction: column;

      & > div {
        display: flex;
        flex-direction: column;

        & h2 {
          margin-top: 1rem;
          text-overflow: ellipsis !important;
          overflow: hidden;
          width: 250px;
          font-size: 20px;

          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2; /* Número de linhas que deseja exibir */
          -webkit-box-orient: vertical;
        }

        & span {
          text-overflow: ellipsis;
        }
      }
    }
    &:last-child {
      margin-top: 1rem;
      background-color: #f5f5f5;
      border-radius: 8px;
      display: flex;
      flex-direction: row;
      font-size: 14px;

      & > div {
        flex: 1;
        padding: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        text-align: center;
        max-width: calc(100% / 3);

        & strong {
          margin: 0.3rem 0;
        }

        &:nth-child(2) {
          border-left: 1px solid rgba(0, 0, 0, 0.06);
          border-right: 1px solid rgba(0, 0, 0, 0.06);
        }
      }
    }
  }

  ${media('<=tablet')} {
    min-width: auto !important;
    width: 300px !important;

    & > div:last-child {
      & span {
        font-size: 10pt;
      }

      & strong {
        font-size: 11pt !important;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  ${media('<=phone')} {
    min-width: auto !important;
    width: 280px !important;

    & > div:last-child {
      & span {
        font-size: 8pt;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      & strong {
        font-size: 11pt !important;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
`;

const Avatar = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  font-size: 12px;

  div {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
  }

  p {
    margin-left: 15px;
  }

  img {
    max-width: 16%;
    border-radius: 50%;
  }

  ${media('<=tablet')} {
    img {
      max-width: 25%;
    }
  }
`;

const Description = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`;

export default PoolCard;
