import { useCallback, useEffect, useState } from 'react';
import { FaSearch, FaTrash } from 'react-icons/fa';
import Select from 'react-select';
import styled from 'styled-components';
import { api } from 'services/apiClient';
import Button from './Button';
import { media } from 'utils/media';

export type PoolFilter = {
  period?: string;
  categoryId?: string;
  state?: string;
};

export interface SelectItem {
  label: string;
  value?: string;
}

export type PoolCategory = {
  id?: string;
  name: string;
  image: string;
};

const periods = [
  { label: 'Hoje', value: 'today' },
  { label: 'Esta Semana', value: 'this_week' },
  { label: 'Este Mês', value: 'this_month' },
  { label: 'Este Ano', value: 'this_year' },
];

const states = [
  { label: 'Acre', value: 'AC' },
  { label: 'Alagoas', value: 'AL' },
  { label: 'Amapá', value: 'AP' },
  { label: 'Amazonas', value: 'AM' },
  { label: 'Bahia', value: 'BA' },
  { label: 'Ceará', value: 'CE' },
  { label: 'Distrito Federal', value: 'DF' },
  { label: 'Espírito Santo', value: 'ES' },
  { label: 'Goiás', value: 'GO' },
  { label: 'Maranhão', value: 'MA' },
  { label: 'Mato Grosso', value: 'MT' },
  { label: 'Mato Grosso do Sul', value: 'MS' },
  { label: 'Minas Gerais', value: 'MG' },
  { label: 'Pará', value: 'PA' },
  { label: 'Paraíba', value: 'PB' },
  { label: 'Paraná', value: 'PR' },
  { label: 'Pernambuco', value: 'PE' },
  { label: 'Piauí', value: 'PI' },
  { label: 'Rio de Janeiro', value: 'RJ' },
  { label: 'Rio Grande do Norte', value: 'RN' },
  { label: 'Rio Grande do Sul', value: 'RS' },
  { label: 'Rondônia', value: 'RO' },
  { label: 'Roraima', value: 'RR' },
  { label: 'Santa Catarina', value: 'SC' },
  { label: 'São Paulo', value: 'SP' },
  { label: 'Sergipe', value: 'SE' },
  { label: 'Tocantins', value: 'TO' },
];

export interface FilterPoolCardProps {
  onSearch?(filter?: PoolFilter): void;
}

export default function FilterPoolCard({ onSearch }: FilterPoolCardProps) {
  const [filter, setFilter] = useState<PoolFilter>();
  const [categories, setCategories] = useState<SelectItem[]>([]);

  const loadCategories = useCallback(async () => {
    const { data: categories } = await api.get<PoolCategory[]>(`/pool-categories`);
    const normalized = categories.map(({ name: label, id: value }) => {
      return {
        label,
        value,
      };
    });
    setCategories(normalized);
  }, []);

  useEffect(() => {
    loadCategories();
  }, [loadCategories]);

  const handlerSearch = useCallback(() => {
    filter && onSearch?.(filter);
  }, [filter, onSearch]);

  const handlerCleanSearch = useCallback(() => {
    setFilter(undefined);
    onSearch?.(undefined);
  }, [onSearch]);

  return (
    <div>
      <ExploreCard>
        <div>
          <div>
            <div>
              <h1>Explorar vaquinhas</h1>
            </div>
            <div>
              <div>
                <Select
                  value={
                    filter?.period
                      ? undefined
                      : {
                          label: 'Criadas em qualquer data',
                          value: 'Criadas em qualquer data',
                        }
                  }
                  isSearchable={false}
                  onChange={(newValue: any) =>
                    setFilter((prev: any) => {
                      return {
                        ...prev,
                        period: newValue.value,
                      };
                    })
                  }
                  placeholder="Períodos"
                  options={[...periods]}
                  styles={{
                    control: (base) => ({
                      ...base,
                      borderRadius: 4,
                      width: '200px',
                    }),
                    input: (base) => ({
                      ...base,
                      height: 35,
                    }),
                    container: (base) => ({
                      ...base,
                      fontSize: '1.3rem',
                    }),
                  }}
                />
              </div>
              <div>
                <Select
                  value={
                    filter?.state
                      ? undefined
                      : {
                          label: 'Todas as localizações',
                          value: 'Todas as localizações',
                        }
                  }
                  isSearchable={false}
                  onChange={(newValue) =>
                    setFilter((prev) => {
                      return {
                        ...prev,
                        state: newValue?.value ?? '',
                      };
                    })
                  }
                  placeholder="Todas as localizações"
                  options={states}
                  styles={{
                    control: (base) => ({
                      ...base,
                      borderRadius: 4,
                      width: '200px',
                    }),
                    input: (base) => ({
                      ...base,
                      height: 35,
                    }),
                    container: (base) => ({
                      ...base,
                      fontSize: '1.3rem',
                    }),
                  }}
                />
              </div>
              <div>
                <Select
                  value={
                    filter?.categoryId
                      ? undefined
                      : {
                          label: 'Todas as categorias',
                          value: 'Todas as categorias',
                        }
                  }
                  isSearchable={false}
                  onChange={(newValue) =>
                    setFilter((prev) => {
                      return {
                        ...prev,
                        categoryId: newValue?.value ?? '',
                      };
                    })
                  }
                  placeholder="Todas as categorias"
                  options={categories as any}
                  styles={{
                    control: (base) => ({
                      ...base,
                      borderRadius: 4,
                      width: '200px',
                    }),
                    input: (base) => ({
                      ...base,
                      height: 35,
                    }),
                    container: (base) => ({
                      ...base,
                      fontSize: '1.3rem',
                    }),
                  }}
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <Button onClick={handlerSearch}>
                  Buscar{' '}
                  <FaSearch
                    style={{
                      marginLeft: 5,
                    }}
                  />
                </Button>
                <Button
                  style={{
                    marginLeft: 10,
                  }}
                  onClick={handlerCleanSearch}
                >
                  <FaTrash />
                </Button>
              </div>
            </div>
          </div>
        </div>
      </ExploreCard>
    </div>
  );
}

const ExploreCard = styled.div`
  display: flex;
  justify-content: center;
  z-index: 10;
  flex: 1;

  & > img {
    position: absolute;
    width: 100%;
    height: auto;
    top: -300px;
    left: 0;
  }

  & > div {
    width: 100%;
    max-width: 130em;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99;

    & > div {
      display: flex;
      flex-direction: column;

      background-color: #FBF9ED;
      cursor: auto;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
      border-bottom-left-radius: 8px;
      box-shadow: rgb(223, 223, 223) 4px 5px 4px 5px;

      & h1 {
        color: #000000;
        font-size: large;
        font-weight: bold;
        padding: 16px 32px;
        text-align: center;
      }

      & button {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 42px;
        border-radius: 4px;

        & > *:last-child {
          color: #ffffff;
        }
      }

      & > div:last-child {
        display: flex;
        padding: 32px;
        padding-top: 0;
        flex-direction: row;
        gap: 10px;

        display: flex;
        flex-direction: row;
        align-items: center;
      }
    }
  }

  & select {
    border: none;
    background-color: white;
    text-decoration: none;
    text-align: center;
    padding: 1.5rem;
    font-size: 1.2rem;
    border-radius: 2rem;
    transition: transform 0.3s;
    height: 49px;
    min-width: 150px;
    border: solid 1px #cccccc;
    outline: none;

    &::-webkit-user-select {
      color: red;
    }
  }

  ${media('<=tablet')} {
    & > div > div > div:last-child {
      flex-direction: column;
    }

    & button {
      margin-top: 2rem;
    }
  }
`;
